<template>
  <div class="thank-you-page">
    <v-container fluid fill-height class="mt-5">
      <v-card flat class="elevation-0">
        <CategoryTitle :category="category" />
        <v-card-actions
          class="justify-center"
          v-if="showLogin && !isAuthenticated"
        >
          <v-btn @click="handleLogin" color="primary" large depressed block>
            Accedi ora
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import category from "~/mixins/category";
import login from "~/mixins/login";
import CategoryTitle from "@/components/category/CategoryTitle";
export default {
  name: "ResetPasswordSent",
  mixins: [category, login],
  props: { showLogin: { type: Boolean, default: false } },
  components: { CategoryTitle },
  methods: {
    async handleLogin() {
      await this.doLogin("EbsnCategory");
      if (await this.needAddress()) {
        await this.needTimeslot();
        this.$router.push({
          name: "Home",
          path: "/"
        });
      }
    }
  },
  created() {
    //this.isLoggedIn("EbsnCategory");
  }
};
</script>

<style></style>
