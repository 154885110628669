<template>
  <div v-if="category" class="category-title">
    <ebsn-style :target="category" path="category_advanced.CSS_STYLE" />
    <v-img
      v-if="headerImage && !$vuetify.breakpoint.xs && !isTitleForBrand"
      dark
      :src="headerImage"
      class="elevation-0 rounded-0"
      @load="loaded = true"
    >
    </v-img>

    <h1
      v-if="!isTitleForBrand"
      class="text-center text-sm-left primary--text text--lighten-1"
      :class="{
        'd-none': $ebsn.meta(category, 'metaData.category_info.HIDE_TITLE')
      }"
    >
      {{ title }}
      <span v-if="count > 0">:{{ count }} prodotti</span>
    </h1>
    <div color="primary" class="description my-4" v-html="description"></div>
    <v-container
      fluid
      v-if="showSub && category.children"
      class="subcategories pa-0 pb-2"
    >
      <v-chip-group show-arrows class="subcategories-chips-row">
        <v-chip
          v-for="subCategory in category.children"
          :key="subCategory.categoryId"
          label
          link
          :to="{ name: 'Category', params: { pathMatch: subCategory.slug } }"
          filter
          class="mr-2 mb-2"
        >
          {{ subCategory.name }}
        </v-chip>
      </v-chip-group>
    </v-container>
  </div>
</template>
<style lang="scss">
.brand-subtitle {
  color: var(--v-primary-lighten1);
  font-weight: 600;
  font-size: 16px !important;
}
.brand-header-description {
  font-size: 14px !important;
}
.description {
  line-height: 1.5;
}
.description-text {
  overflow: hidden;
  max-height: 35px;
  transition: all 0.5s ease;
  &.expanded {
    max-height: 100%;
  }
}
.subcategories {
  display: flex;
  .v-icon {
    top: -4px;
    &.prev {
      left: -7px;
    }
    &.next {
      right: -7px;
    }
    &::after {
      border-radius: $border-radius-root;
    }
  }
  .subcategories-chips-row {
    overflow: hidden;
    white-space: nowrap;
    .v-chip {
      display: inline-block;
    }
  }
}
</style>
<script>
// import get from "lodash/get";
import clickHandler from "~/mixins/clickHandler";

export default {
  name: "CategoryTitle",
  mixins: [clickHandler],
  props: {
    count: { type: Number, required: false },
    category: { type: Object, required: true },
    selectedCategory: { type: Object, required: false },
    showSub: { type: Boolean, default: false },
    isTitleForBrand: { type: Boolean, default: false },
    expandedDescription: { type: Boolean, default: false },
    lockDescription: { type: Boolean, default: false }
    // textColor: {type: String, default: '#636363'}
  },
  data: function() {
    return { loaded: false, scrollAmount: 0, expanded: false };
  },
  computed: {
    title() {
      let c = this.selectedCategory ? this.selectedCategory : this.category;
      return this.$ebsn.meta(c, "metaData.category_info.TITLE", c.name);
    },
    headerImage() {
      return this.$ebsn.meta(
        this.category,
        "metaData.category_info.HEADER_IMAGE"
      );
    },
    description() {
      return this.$ebsn.meta(
        this.category,
        "metaData.category_info.DESCRIPTION",
        null
      );
    },
    scrollArrows() {
      return this.category.children && this.category.children.length > 0;
    }
  },
  methods: {
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft;
      // Condition to check if scrolling is required
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth ||
            scrollPixels < 0)
        )
      ) {
        // Get the start timestamp
        const startTime =
          "now" in window.performance
            ? performance.now()
            : new Date().getTime();

        let scroll = function(timestamp) {
          //Calculate the timeelapsed
          const timeElapsed = timestamp - startTime;
          //Calculate progress
          const progress = Math.min(timeElapsed / duration, 1);
          //Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress;
          //Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            //Request for animation
            window.requestAnimationFrame(scroll);
          } else {
            return;
          }
        };
        //Call requestAnimationFrame on scroll function first time
        window.requestAnimationFrame(scroll);
      }
    }
  },
  created() {
    this.expanded = this.expandedDescription;
  }
};
</script>
